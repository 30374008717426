import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import styles from './Hero.module.scss';

const Hero = ({ image }) => (
  <div className={styles.container}>
    <Image src={image} alt="" layout="fill" objectFit="cover" priority />
  </div>
);

export default Hero;

Hero.propTypes = {
  image: PropTypes.string,
};
